<template lang="pug">
	main#home
		.wrapper
			Logo
			p
				| Bem-vindo ao painel de administrador do Visibox
			form(@submit.prevent="login")
				.input(:class="{'error': errors.email }")
					input(placeholder="E-mail", type="text" v-model="form.email")
					span(v-if="errors.email") {{ errors.email[0] }}
				.input(:class="{'error': errors.password || errors.message }")
					input(placeholder="Senha", type="password" v-model="form.password")
					span(v-if="errors.password") {{ errors.password[0] }}
					span(v-if="errors.message") {{ errors.message }}
				button(v-html="buttonText", :disabled="disabled", type="submit").btnX
				router-link(:to="{name: 'Recovery'}") Esqueci a senha
</template>

<script>
import Logo from '@components/Logo/Logo.vue'

export default {
	name: 'view-home',
	metaInfo() {
		return {
			title: 'Visibox | Painel',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
	components: {
		Logo,
	},
	data() {
		return {
			form: {
				email: '',
				password: '',
				isAdmin: true,
			},
			errors: {},
			buttonText: "Entrar",
			disabled: false
		}
	},
	methods: {
		login() {
			this.errors = {}
			this.disabled = true
			this.buttonText = "Validando..."
			this.$axios.post('auth/', this.form)
				.then(response => {
					this.$axios.defaults.headers.Authorization = `Bearer ${response.data.token}`
					localStorage.setItem('TOKEN', `Bearer ${response.data.token}`)
					localStorage.setItem('USER_NAME', response.data.name)
					localStorage.setItem('USER_ID', response.data.id)
					this.$router.push({name: 'Painel'})
				}).catch(error => {
					this.errors = error.response.data
				}).finally(() => {
					setTimeout(() => {
                        this.disabled = false
                        this.buttonText = "Enviar"
                    }, 2000);
				})
		}
	}
}
</script>

<style lang="stylus" scoped src="./Home.styl"></style>
